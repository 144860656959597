import React from "react";

/**
 * @param {RInputProps} props
 */
function RInput(props) {
  const col = (props.col && props.col != 12) ? ' col-md-' + props.col : ''
  const InputClass = "RInput form-group col-12" + col
  return (
    <div className={InputClass}>
      <label htmlFor={props.name}>{props.label}</label>
      <input
        className="form-control"
        type={props.type}
        placeholder={props.label}
        id={props.name}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
      />
      <span className="form-text text-danger">{props.error}</span>
    </div>
  )
}

RInput.defaultProps = {
  type: 'text'
}

export default RInput

/**
 * @typedef {Object} RInputProps
 * @property {String} value
 * @property {String} error
 * @property {String} type
 * @property {String} name
 * @property {String} label
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange
 */
