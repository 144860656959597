export const defaultFormState = {
  email: '',
  subject: '',
  name: '',
  message: '',
  rgpd: false,
  validation: ''
}

export const defaultFormErrorState = {
  email: '',
  subject: '',
  name: '',
  message: '',
  rgpd: ''
}
