import React from "react";

/**
 * @param {RInputProps} props
 */
function RTextArea(props) {
  const col = (props.col && props.col != 12) ? ' col-md-' + props.col : ''
  const InputClass = "RTextArea form-group col-12" + col
  return (
    <div className={InputClass}>
      <label htmlFor={props.name}>{props.label}</label>
      <textarea
        className="form-control"
        type={props.type}
        id={props.name}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.label}
      />
      <span className="form-text text-danger">{props.error}</span>
    </div>
  )
}

export default RTextArea

/**
 * @typedef {Object} RInputProps
 * @property {String} value
 * @property {String} error
 * @property {String} type
 * @property {String} name
 * @property {String} label
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange
 */
