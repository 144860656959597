import React, {Component} from 'react'
import Schema from "./modules/contactFormValidation";
import { firstErrorOrEmpty } from "./modules/getError";
import { reach }  from 'yup'
import axios from "./modules/axios";
import { defaultFormState, defaultFormErrorState } from './defaultState';
import RInput from './Components/RInput/RInput';
import RTextArea from './Components/RTextArea/RTextArea';
import update from "immutability-helper";

/**
 * Renders a contact form
 *
 * @class ContactForm
 * @extends {Component}
 */
class ContactForm extends Component {
  /** @param {Object<never>} props*/
  constructor(props) {
    super(props)

    this.change = this.change.bind(this)
    this.submit = this.submit.bind(this)
    this.setGlobalMessage = this.setGlobalMessage.bind(this)

    this.state = {
      form: defaultFormState,
      formErrors: defaultFormErrorState,
      globalMessage: '',
      globalMessageType: ''
    }
  }

  /** @param {React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>} event */
  async change({target}) {
    const {value, name} = target

    this.setState(({ form }) => ({ form: update(form, {
      [name]: {$set: value}
    })}))

    try {
      await reach(Schema('pt'), name).validate(value)
      this.setState(({formErrors}) => ({
        formErrors: update(formErrors, {
          [name]: {$set: ''}
        })
      }))
    } catch (err) {
      this.setState(({formErrors}) => ({
        formErrors: update(formErrors, {
          [name]: {$set: err.message}
        })
      }))
    }
  }

  setErrors(errors) {
    const reduceError = (prev, val) => Object.assign({}, prev, { [val]: firstErrorOrEmpty(errors, val) })

    return this.setState(state => ({
      formErrors: Object.assign(
        {},
        state.formErrors,
        Object.keys(state.formErrors).reduce(reduceError, {})
      )
    }))
  }

  /**
   * @param {string} error
   * @param {'danger'|'success'} type
   * @memberof ContactForm
   */
  setGlobalMessage(error, type) {
    this.setState({
      globalMessage: error ,
      globalMessageType: type
    })

    setTimeout(() => {
      this.setState({
        globalMessage: '',
        globalMessageType: ''
      })
    }, 3000);
  }

  /** @param {React.FormEvent<HTMLFormElement>} event */
  async submit(event) {
    event.preventDefault()

    try {
      await Schema('pt').validate(this.state.form, { abortEarly: false })

      this.setState({formErrors: defaultFormErrorState})
    } catch (err) {
      if (err.name != "ValidationError") {
        this.setGlobalMessage('Ocorreu um erro no envio de dados', 'danger')
        throw err
      }

      return this.setErrors(err.inner)
    }

    try {
      await axios.post('/contactos', this.state.form)

      this.setState({form: defaultFormState})

      this.setGlobalMessage('Conteudo enviado com sucesso', 'success')
    } catch (err) {
      if (!err.response || err.response.status !== 422) {
        return this.setGlobalMessage('Ocorreu um erro no envio de dados', 'danger')
      }

      const { errors } = err.response.data

      const errorObject = Object.keys(errors).reduce((prev, val) =>
        Object.assign({}, prev, {[val]: errors[val]})
      , {})

      this.setState(state => ({
        formErrors: Object.assign({}, state.formErrors, errorObject)
      }))
    }
  }

  render() {
    return (
      <form
        onSubmit={this.submit}
        className="row RContactForm"
      >
        <RInput
          type="text"
          col={6}
          label="Nome"
          name="name"
          onChange={this.change}
          error={this.state.formErrors.name}
          value={this.state.form.name}
        />
        <RInput
          type="email"
          label="Email"
          col={6}
          name="email"
          onChange={this.change}
          error={this.state.formErrors.email}
          value={this.state.form.email}
        />
        <RInput
          type="text"
          label="Assunto"
          name="subject"
          onChange={this.change}
          error={this.state.formErrors.subject}
          value={this.state.form.subject}
        />
        <RTextArea
          name="message"
          label="Mensagem"
          onChange={this.change}
          error={this.state.formErrors.message}
          value={this.state.form.message}
        />
        <div className="form-group col-12">
          <div className="form-check">
            <input
              type="checkbox"
              name="rgpd"
              id="rgpd"
              checked={this.state.form.rgpd}
              onChange={this.change}
              required
              className="form-check-input"
            />
            <label htmlFor="rgpd" className="form-check-label">
              Dou o meu consentimento para que a Bom Clima responda às minhas questões, bem como, me envie informação sobre os seus produtos e serviços.
            </label>
          </div>
        </div>
        <input
          type="hidden"
          value={this.state.form.validation}
          onChange={this.change}
          name="validation"
        />
        <div className="form-action col-12">
          <button
            type="submit"
            className="btn btn-primary"
          >
            Submeter
          </button>
          <span
            className={'mx-3 globalMessage text-' + this.state.globalMessageType}
          >
            {this.state.globalMessage}
          </span>
        </div>
      </form>
    )
  }
}

export default ContactForm
