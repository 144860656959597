import {string, object, required, email, min} from 'yup';

const langs = {
  pt: {
    email: {
      required: 'O email é obrigatório',
      email: 'Email encontra-se com formato inválido'
    },
    subject: {
      required: 'O assunto é obrigatório',
      min: 'O assunto não pode conter menos que 3 caracteres'
    },
    name: {
      required: 'O nome é obrigatório',
      min: 'O nome não pode conter menos que 3 caracteres'
    },
    message: {
      required: 'A mensagem é obrigatória',
      min: 'A mensagem não pode conter menos de 10 caracteres'
    }
  }
}

/**
 * Validate the contact form state
 *
 * @param {String} selectedLang
 * @returns {yup.ObjectSchema}
 */
function contactFormValidation(selectedLang) {
  const validation = langs[selectedLang]
  return object({
    email: string().required(validation.email.required).email(validation.email.email),
    subject: string().required(validation.subject.required).min(3, validation.subject.min),
    name: string().required(validation.name.required).min(3, validation.name.min),
    message: string().required(validation.message.required).min(10, validation.message.min),
  })
}

export default contactFormValidation
